import {useInfiniteQuery} from '@tanstack/react-query';
import {propOr} from 'ramda';
import type {
  IApiResponse,
  IPagination,
  IQueryMetadata,
  ISeries,
  ISeriesBm,
  IUseInfiniteQuery5,
} from '../../../types';
import {instApiAxios} from '../../_axios';
import {getNextPageParam} from '../../_queryClient5';

const QueryKey = 'GET:product/series';

export interface InfQuerySeriesListValues
  extends Pick<IPagination, 'paginationSort'> {}

export const useInfQuerySeriesList: IUseInfiniteQuery5<
  IApiResponse<
    {
      series: ISeries &
        {
          businessModelInfo?: Pick<ISeriesBm, 'bmType' | 'useWaitFree'> & {
            bmName: string;
          };
        }[];
    },
    IQueryMetadata
  >,
  InfQuerySeriesListValues
> = function ({values, options} = {}) {
  return useInfiniteQuery({
    queryKey: [QueryKey, values],
    queryFn: async function ({pageParam}) {
      const axios = await instApiAxios();
      return axios
        .get('/product/series', {
          params: {
            ...values,
            paginationOffset: propOr(0, 'paginationOffset', pageParam),
            paginationLimit: propOr(20, 'paginationLimit', pageParam),
          },
        })
        .then(response => response.data);
    },
    initialPageParam: {paginationOffset: 0, paginationLimit: 50},
    getNextPageParam,
    ...options,
  });
};

export default QueryKey;
