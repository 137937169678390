import {useQuery} from '@tanstack/react-query';
import type {IPartnerServiceInfo, IUseQuery5} from '../../types';
import {instAsisAxios} from '../_axios';

const QueryKey = 'GET:/partner/monitor/info';

/**
 * ASIS 파트너사이트 서비스 정보 가져오기 Query
 *
 * @param args
 */
export const useQueryAsisPartnerSiteSvcInfo: IUseQuery5<IPartnerServiceInfo> =
  function ({options} = {}) {
    return useQuery({
      queryKey: [QueryKey],
      queryFn: async function () {
        const axios = await instAsisAxios();
        return axios
          .get<IPartnerServiceInfo>('/partner/monitor/info', {})
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
