import {useQuery} from '@tanstack/react-query';
import {is} from 'ramda';
import {IApiResponse, IOperatorRole, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/user/publisher/{publisherUid}/operator/roles';

/**
 * 계정 권한 목록 조회
 * @param args
 * @returns
 */
type QueryOperatorRolesResponse = IApiResponse<
  {operatorRoles?: IOperatorRole[]},
  {operatorRoleCount?: number}
>;

export const useQueryOperatorRoles: IUseQuery5<
  QueryOperatorRolesResponse,
  {publisherUid?: number}
> = function (args = {}) {
  const {values, options} = args;
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async () => {
      const axios = await instApiAxios();
      return axios
        .get<QueryOperatorRolesResponse>(
          `/user/publisher/${values?.publisherUid}/operator/roles`
        )
        .then(response => response.data);
    },
    enabled: is(Number, values?.publisherUid),
    ...options,
  });
};
