import {useInfiniteQuery} from '@tanstack/react-query';
import {pick, propOr} from 'ramda';
import type {
  IApiResponse,
  IPackagedSingle,
  IPagination,
  ISingleState,
  ISlideType,
  IUseInfiniteQuery5,
} from '../../../types';
import {instApiAxios} from '../../_axios';
import {getNextPageParam} from '../../_queryClient5';

const QueryKey = 'GET:/product/series/{seriesId}/single/packaged';

export interface IUseInfQueryPackagedSingleListValues
  extends Pick<IPagination, 'paginationSort'> {
  seriesId: number;
  singleTypes?: ISlideType[];
  singleStates?: ISingleState[];
}

/**
 * 작품의 패키지 싱글 리스트를 조회합니다.
 * @param values
 * @param options
 * @returns
 */
export const useInfQueryPackagedSingleList: IUseInfiniteQuery5<
  IApiResponse<{packagedSingles: Array<IPackagedSingle>}>,
  IUseInfQueryPackagedSingleListValues
> = function ({values, options} = {}) {
  return useInfiniteQuery({
    queryKey: [QueryKey, values],
    queryFn: async function ({pageParam}) {
      const axios = await instApiAxios();
      return axios
        .get(`/product/series/${values?.seriesId}/single/packaged`, {
          params: {
            ...pick(
              ['singleTypes', 'singleStates', 'paginationSort'],
              values ?? ({} as IUseInfQueryPackagedSingleListValues)
            ),
            paginationOffset: propOr(0, 'paginationOffset', pageParam),
            paginationLimit: propOr(50, 'paginationLimit', pageParam),
          },
        })
        .then(response => response.data);
    },
    initialPageParam: {paginationOffset: 0, paginationLimit: 50},
    getNextPageParam,
    ...options,
  });
};

export default QueryKey;
