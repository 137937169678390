import {useMutation} from '@tanstack/react-query';
import {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * email otp 발송 api
 * @param args
 */
export const useCreateEmailOtp: IUseMutation5<
  boolean,
  {email?: string; operatorId?: number}
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async values => {
      const axios = await instApiAxios();

      // email 중복 검사
      await axios.get<boolean>('/user/operator/validate/email', {
        params: values,
      });

      // email otp 생성
      return axios
        .post<boolean>('/user/operator/validate/email/otp', {
          email: values.email,
        })
        .then(response => response.data);
    },
    ...options,
  });
};
