import {pick} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import {
  IApiResponse,
  IPagination,
  ISingleAndProductSale,
  ISingleStateAndSaleMethod,
  IUseQuery5,
} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/series/{seriesId}/payment-method/cash/single';

export interface QuerySeriesPriceValues extends IPagination {
  isLatestRevision?: boolean;
  seriesId: number;
  singleIds?: number[];
  stateAndSaleMethods?: ISingleStateAndSaleMethod[];
}

/**
 * 작품 판매설정 회차 가격 정보 목록 조회
 * @param values
 * @param options
 * @returns QueryObserverResult
 */
export const useQuerySingleProductSaleList: IUseQuery5<
  IApiResponse<{singleAndProductSaleInformationList?: ISingleAndProductSale[]}>,
  QuerySeriesPriceValues
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get(`product/series/${values?.seriesId}/payment-method/cash/single`, {
          params: {
            ...pick(
              [
                'isLatestRevision',
                'paginationOffset',
                'paginationLimit',
                'paginationSort',
                'singleIds',
                'stateAndSaleMethods',
              ],
              values ?? ({} as QuerySeriesPriceValues)
            ),
          },
        })
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
