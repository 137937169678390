import {flatten, includes, map, prop, split} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {
  IApiResponse,
  ISetSeriesList,
  ISetStateAndSaleMethods,
  IUseQuery5,
} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/series/{seriesId}/set';

/**
 * 세트상품 목록 조회
 * @param options
 * @returns
 */
export const useQuerySetSeriesList: IUseQuery5<IApiResponse<ISetSeriesList>> =
  function ({values, options} = {}) {
    return useQuery({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        const seriesId = prop('seriesId', values);

        return axios
          .get(`/product/series/${seriesId}/set`, {
            params: {
              ...values,
              ...{
                setStateAndSaleMethods: filterSetSeriesListValues(
                  values.setStateAndSaleMethods
                ),
              },
            },
          })
          .then(response => response.data);
      },
      ...options,
    });
  };

function filterSetSeriesListValues(
  setStateAndSaleMethods?: ISetStateAndSaleMethods[]
) {
  const newMethods = map(item => {
    return includes(',', item[0]) ? split(',', item[0]) : item[0];
  }, setStateAndSaleMethods ?? []);

  return flatten(newMethods);
}

export default QueryKey;
