import {
  assoc,
  isNilEmpty,
  map,
  omit,
  pick,
  pipe,
  reject,
} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {
  ISeriesTicket,
  ISeriesTicketPackage,
  IUseMutation5,
} from '../../../types';
import {instApiAxios} from '../../_axios';

const MutationKey = 'POST:/product/series/{seriesId}/ticket';

export interface IQuerySeriesTicketValues {
  seriesId: number;
}

/**
 * 작품 이용권정보 변경
 * @param {UseMutationOptions} options - 작품 이용권정보 변경 뮤테이션 옵션
 * @returns {UseMutationResult}
 */
export const useUpdateSeriesTicket: IUseMutation5<
  ISeriesTicket,
  {seriesId: number; values: Partial<ISeriesTicket>}
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function ({seriesId, values}) {
      const axios = await instApiAxios();

      reject(isNilEmpty, values);

      const filteredValues = pipe(
        (seriesTicket: Partial<ISeriesTicket>) => {
          return reject(isNilEmpty, seriesTicket);
        },
        // API스팩에 마제 이용권 패키지 정보 매핑
        (seriesTicket: Partial<ISeriesTicket>) => {
          function mapTicketPackageList(
            ticketPackageList: Array<ISeriesTicketPackage>
          ) {
            return map(
              ticketPackage => ({
                position: ticketPackage.position,
                useYn: ticketPackage.useYn ?? false,
                totalTicketCount: ticketPackage.totalTicketCount,
                paidTicketCount: ticketPackage.paidTicketCount,
              }),
              ticketPackageList ?? []
            );
          }
          seriesTicket = assoc(
            'buyTicketPackageInformationList',
            mapTicketPackageList(
              seriesTicket.buyTicketPackageInformationList ?? []
            ),
            seriesTicket
          );
          seriesTicket = assoc(
            'rentTicketPackageInformationList',
            mapTicketPackageList(
              seriesTicket.rentTicketPackageInformationList ?? []
            ),
            seriesTicket
          );

          return seriesTicket;
        },
        // 소장권/대여권 사용유무에 따라 정보 필터링
        (seriesTicket: Partial<ISeriesTicket>) => {
          if (!seriesTicket.useBuyTicket) {
            seriesTicket = omit(
              ['buyTicketUnitPrice', 'buyTicketPackageInformationList'],
              seriesTicket
            );
          }
          if (!seriesTicket.useRentTicket) {
            seriesTicket = omit(
              ['rentTicketUnitPrice', 'rentTicketPackageInformationList'],
              seriesTicket
            );
          }
          return seriesTicket;
        },
        // API 스펙에 맞게 매핑
        (seriesTicket: Partial<ISeriesTicket>) => ({
          ...pick(
            [
              'useRentTicket',
              'useBuyTicket',
              'rentTicketUnitPrice',
              'buyTicketUnitPrice',
            ],
            seriesTicket
          ),
          newCustomerBenefitTicket:
            seriesTicket.newCustomerBenefitTicketInformation,
          rentTicketPackages: map(
            packageInfo => ({
              ...packageInfo,
              totalTicketCount: packageInfo.totalTicketCount ?? '',
              paidTicketCount: packageInfo.paidTicketCount ?? '',
            }),
            seriesTicket.rentTicketPackageInformationList ?? []
          ),
          buyTicketPackages: map(
            packageInfo => ({
              ...packageInfo,
              totalTicketCount: packageInfo.totalTicketCount ?? '',
              paidTicketCount: packageInfo.paidTicketCount ?? '',
            }),
            seriesTicket.buyTicketPackageInformationList ?? []
          ),
        })
      )(values);

      return axios
        .post(`product/series/${seriesId}/ticket`, filteredValues)
        .then(response => response.data);
    },
    ...options,
  });
};

export default MutationKey;
