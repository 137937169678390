import {useQuery} from '@tanstack/react-query';
import type {IPartnerServiceInfo, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/monitor/info';

/**
 * CMS 상품 서비스(이브이) 정보 가져오기 Query
 *
 * @param args
 */
export const useQueryCmsProductSvcInfo: IUseQuery5<IPartnerServiceInfo> =
  function ({options} = {}) {
    return useQuery({
      queryKey: [QueryKey],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get<IPartnerServiceInfo>('/product/monitor/info', {})
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
