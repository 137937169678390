import {isNilEmpty, map, omit, pick, pipe, trim} from '@kakaoent/ops-design';
import type {ISeriesForm} from '../../../types';

export function mapSeriesFormToApiValues(seriesForm: Partial<ISeriesForm>) {
  return pipe((values: Partial<ISeriesForm>) => ({
    ...values,
    authorInfoList: map(
      authorInfo =>
        pick(['authorUid', 'authorTypeList', 'orderValue'], authorInfo),
      values.authorInfoList ?? []
    ),
    kakaoWebtoonMappingInfo: isNilEmpty(values.kakaoWebtoonMappingInfo)
      ? undefined
      : values.kakaoWebtoonMappingInfo,
    searchKeywordList: map(
      searchKeyword => trim(searchKeyword),
      values.searchKeywordList ?? []
    ),
    talkViewerInfo: pick(
      ['talkViewerId', 'talkViewerType'],
      values.talkViewerInfo ?? {}
    ),
    themeKeywordInfoList: map(
      themeKeywordInfo => omit(['themeKeywordName'], themeKeywordInfo),
      values.themeKeywordInfoList ?? []
    ),
  }))(seriesForm);
}
