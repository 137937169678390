import {useMutation} from '@tanstack/react-query';
import type {IUseMutation5} from '../../../types';
import {mapFormData} from '../../../utils/network';
import {instApiAxios} from '../../_axios';

const MutationKey = 'POST:/product/series/image/screen-shot';

export interface UploadScreenshotImageValues {
  uploadImageFile?: File;
}

export interface UploadScreenshotImageResponse {
  screenShotUrl: {
    uploadId: string;
    originalFilename?: string;
  };
  screenShotThumbnailUrl: {
    uploadId: string;
    originalFilename?: string;
  };
}

/**
 * 작품 스크린샷 업로드(케이지)
 */
export const useUploadScreenshotImage: IUseMutation5<
  UploadScreenshotImageResponse,
  UploadScreenshotImageValues
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const formData: FormData = mapFormData(values);
      return axios
        .post('/product/series/image/screen-shot', formData, {
          headers: {'Content-Type': 'multipart/form-data;'},
          timeout: 0,
        })
        .then(response => response.data);
    },
    ...options,
  });
};

export default MutationKey;
