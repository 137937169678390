import {isNilEmpty} from '@kakaoent/ops-design';
import {QueryClient} from '@tanstack/react-query';
import type {IApiResponse, IPagination} from '../types';

/**
 * react-query queryClient 설정
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const instQueryClient = function () {
  return queryClient;
};

// 인피니티쿼리 다음페이지 조회
export function getNextPageParam(
  lastPage: IApiResponse
): IPagination | undefined {
  if (isNilEmpty(lastPage)) {
    return undefined;
  }

  // 마지막 페이지의 쿼리카운트가 쿼리리밋보다 작으면 다음페이지 없음
  if (
    (lastPage?.metadata?.queryCount ?? 0) <
    (lastPage?.metadata?.queryLimit ?? 0)
  ) {
    return undefined;
  }

  const paginationOffset =
    (lastPage.metadata?.queryOffset ?? 0) +
    (lastPage.metadata?.queryCount ?? 0);
  const paginationLimit = lastPage.metadata?.queryLimit ?? 0;

  if (isNaN(paginationOffset) || isNaN(paginationLimit)) {
    return undefined;
  }
  if (paginationOffset <= 0 || paginationLimit <= 0) {
    return undefined;
  }

  return {
    paginationOffset,
    paginationLimit,
  };
}
