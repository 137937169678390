import {useMutation} from '@tanstack/react-query';
import {
  assoc,
  includes,
  mapObjIndexed,
  mergeDeepRight,
  pipe,
  replace,
  toPairs,
} from 'ramda';
import RSA_PUBLIC_JSON from '../../../.secret/rsa_public_key.json';
import {IOperator, IPublisher, IUseMutation5} from '../../../types';
import {encryptRSA} from '../../../utils/crypto';
import {pairsToFormData} from '../../../utils/network';
import {instServerAxios} from '../../_axios';

/**
 * Publisehr 생성 mutation
 * @param args
 */
export const useCreatePublisher: IUseMutation5<
  IPublisher,
  {publisher?: Partial<IPublisher>; operator?: Partial<IOperator>}
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async values => {
      const axios = await instServerAxios();

      // publisehr, operator 암호화 및 병합
      const encryptedOperator = assoc(
        'password',
        encryptRSA(RSA_PUBLIC_JSON.pem, values.operator?.password ?? ''),
        values.operator
      );
      const mergedValues = mergeDeepRight(
        values.publisher ?? {},
        encryptedOperator
      ) as IPublisher & IOperator;
      const formData = pipe(
        // 스트링에서 '-'제거
        mapObjIndexed((value: any, key) => {
          if (
            includes(key, [
              'businessNumber',
              'cellphone',
              'phone',
              'postNumber',
              'bankAccount',
            ])
          ) {
            return replace(/-/g, '', value);
          }
          return value;
        }),
        // [key, value] 페어 형태로 전환
        toPairs,
        // FormData로 리듀스
        pairsToFormData
      )(mergedValues);

      return axios
        .post<IPublisher>('api/user/publisher', formData, {
          headers: {'Content-Type': 'multipart/form-data;'},
        })
        .then(response => response.data);
    },
    ...options,
  });
};
