import {is, prop} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {IApiResponse, ISeriesAudit, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/audit/series/${seriesId}';

interface UseQuerySeriesAudtitResponse {
  seriesAudits?: Array<ISeriesAudit>;
}

/**
 * 작품 변경이력 조회
 * @param values
 * @param options
 * @returns QueryObserverResult
 */
export const useQuerySeriesAudit: IUseQuery5<
  IApiResponse<UseQuerySeriesAudtitResponse>
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      const seriesId = prop('seriesId', values);

      return axios
        .get(`/product/series/${seriesId}/audit`, {
          params: values,
        })
        .then(response => response.data);
    },
    enabled: is(Number, prop('seriesId', values)),
    ...options,
  });
};

export default QueryKey;
