import {useQuery} from '@tanstack/react-query';
import {isEmpty, trim} from 'ramda';
import {IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/user/operator/validate';

export interface QueryOperatorValidateValues {
  infoPath: string;
}

export interface QueryOperatorValidateResponse {
  businessName: string;
  createdTime: number;
  email: string;
  publisherUid: number;
}

/**
 * 운영자 계정 생성 정보 가져오기 쿼리
 */
export const useQueryOperatorValidate: IUseQuery5<
  QueryOperatorValidateResponse,
  QueryOperatorValidateValues
> = function (args = {}) {
  const {values, options} = args;

  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async () => {
      const infoPath = values?.infoPath;
      if (isEmpty(trim(infoPath ?? ''))) {
        return Promise.reject();
      }
      const axios = await instApiAxios();
      return axios
        .get<QueryOperatorValidateResponse>(
          '/user/operator/validate/' + values?.infoPath
        )
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
