import {isNilEmpty} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {ApiError, IAuthorInfo, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/author/{authorUid}';

export interface QueryAuthorValues {
  authorUid?: number;
}

/**
 * 작가 정보 조회
 * @param {Object} params
 * @param {QueryAuthorValues} params.values - 작가 정보 조회 값
 * @param {UseQueryOptions} params.options - 작가 정보 조회 쿼리 옵션
 * @returns QueryObserverResult
 */
export const useQueryAuthor: IUseQuery5<IAuthorInfo, QueryAuthorValues> =
  function ({values, options} = {}) {
    return useQuery<IAuthorInfo, ApiError>({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get(`product/author/${values?.authorUid}`)
          .then(response => response.data);
      },
      enabled: !isNilEmpty(values?.authorUid),
      ...options,
    });
  };

export default QueryKey;
