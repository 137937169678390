import {isNilEmpty} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import moment from 'moment';
import type {ISeriesSaleAction, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

const MutationKey = 'PUT:/product/series/{seriesId}/sale/{saleAction}';

export interface UpdateSeriesSaleValues {
  seriesId: number;
  saleAction: ISeriesSaleAction;
  emailText?: string;
  reserveDate?: Date | string;
}

/**
 * 작품 판매상태 변경
 * @param {Object} params
 * @param {UpdateSeriesSaleValues} params.values
 * @param {UseQueryOptions<boolean, ApiError, boolean, QueryKey>} params.options
 * @returns QueryObserverResult
 */
export const useUpdateSeriesSale: IUseMutation5<
  boolean,
  UpdateSeriesSaleValues
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      return axios
        .put(
          `/product/series/${values?.seriesId}/sale/${values?.saleAction}`,
          {
            reserveDate: isNilEmpty(values?.reserveDate)
              ? undefined
              : moment(values?.reserveDate).toISOString(),
            emailText: values?.emailText,
          },
          {timeout: 0}
        )
        .then(response => response.data);
    },
    ...options,
  });
};

export default MutationKey;
