import axios, {AxiosInstance, AxiosError} from 'axios';
import qs from 'qs';
import {assocPath, equals, gte, isEmpty, lt, trim} from 'ramda';
import {getEnv} from '../utils/getEnv';
import {isClientBrowser} from '../utils/network';
import {waitSentry} from '../utils/waitSentry';

/** ----------------------------------------------
 * local storage 설정
 */
const KEY_TOKEN_TYPE = 'tokenType';
const KEY_ACCESS_TOKEN = 'accessToken';
const KEY_REFRESH_TOKEN = 'refreshToken';
const KEY_EXPIRES = 'expires';
const KEY_IS_SP_TOKEN = 'isSpToken';
const KEY_IS_ANDROID = 'isAndroid';

const KEY_SAVED_USERNAME_CP = 'savedUsernameCp';
const KEY_SAVED_USERNAME_SP = 'savedUsernameSp';

export const instLocalStorage = function () {
  return isClientBrowser() ? window.localStorage : global.localStorage;
};

export const setUsername = function (
  storage: Storage,
  username: string,
  isSp: boolean = false
) {
  return storage?.setItem(
    isSp ? KEY_SAVED_USERNAME_SP : KEY_SAVED_USERNAME_CP,
    username
  );
};
export const getUsername = function (storage: Storage, isSp: boolean = false) {
  return storage?.getItem(isSp ? KEY_SAVED_USERNAME_SP : KEY_SAVED_USERNAME_CP);
};
export const removeUsername = function (
  storage: Storage,
  isSp: boolean = false
) {
  return storage?.removeItem(
    isSp ? KEY_SAVED_USERNAME_SP : KEY_SAVED_USERNAME_CP
  );
};

export const setToken = function (
  storage: Storage,
  authorization: {
    tokenType: string;
    accessToken: string;
    refreshToken: string;
    expires: string;
  }
) {
  storage?.setItem(KEY_TOKEN_TYPE, authorization.tokenType);
  storage?.setItem(KEY_ACCESS_TOKEN, authorization.accessToken);
  storage?.setItem(KEY_REFRESH_TOKEN, authorization.refreshToken);
  storage?.setItem(KEY_EXPIRES, authorization.expires);
};
export const getToken = function (storage: Storage) {
  return {
    tokenType: storage?.getItem(KEY_TOKEN_TYPE) ?? undefined,
    accessToken: storage?.getItem(KEY_ACCESS_TOKEN) ?? undefined,
    refreshToken: storage?.getItem(KEY_REFRESH_TOKEN) ?? undefined,
    expires: storage?.getItem(KEY_EXPIRES) ?? undefined,
  };
};
export const removeToken = function (storage: Storage) {
  storage?.removeItem(KEY_TOKEN_TYPE);
  storage?.removeItem(KEY_ACCESS_TOKEN);
  storage?.removeItem(KEY_REFRESH_TOKEN);
  storage?.removeItem(KEY_EXPIRES);
};
export const getAuthorization = function (storage: Storage) {
  const tokenType = storage?.getItem(KEY_TOKEN_TYPE);
  const accessToken = storage?.getItem(KEY_ACCESS_TOKEN);

  if (isEmpty(tokenType ?? '') || isEmpty(accessToken ?? '')) {
    return '';
  }
  return `${tokenType} ${accessToken}`;
};

export const setSpLogined = function (storage: Storage, isSp: boolean = false) {
  isSp
    ? storage?.setItem(KEY_IS_SP_TOKEN, 'true')
    : storage?.removeItem(KEY_IS_SP_TOKEN);
};
export const isSpLogined = function (storage: Storage): boolean {
  return !isEmpty(storage?.getItem(KEY_IS_SP_TOKEN) ?? '');
};

export const setAndroidApp = function (
  storage: Storage,
  isAndroid: boolean = false
) {
  isAndroid
    ? storage?.setItem(KEY_IS_ANDROID, 'true')
    : storage?.removeItem(KEY_IS_ANDROID);
};
export const isAndroidApp = function (storage: Storage): boolean {
  return !isEmpty(storage?.getItem(KEY_IS_ANDROID) ?? '');
};

/** ----------------------------------------------
 * axios 설정
 */
export async function captureApiError(error: AxiosError) {
  return await waitSentry().then(Sentry => {
    Sentry.withScope(scope => {
      scope.setLevel('info');
      scope.setFingerprint(['Axios-failed', error.config?.url ?? '']);
      scope.setContext('Request', {
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data,
        params: error.config?.params,
        headers: error.config?.headers,
      });
      scope.setContext('Response', {
        status: error.response?.status,
        statusText: error.response?.statusText,
        data: error.response?.data,
        headers: error.response?.headers,
      });
      error.message = `${error.config?.url ?? '???'} - ${error.message}`;
      scope.captureException(error);
    });
  });
}

/**
 * axios 설정
 */
export const FYRI_NAMESPACE = 'axios-fyri'; // 커스텀 config 저장 namespace
export const DEFAULT_REFRESH = 1; // 토큰 리프레시 시도 횟수
export const DEFAULT_RETRY = 1; // 요청 재시도 횟수
export const createAxios = function (baseURL: string, storage: Storage) {
  const axiosInstance = axios.create({
    baseURL,
    timeout: 15000,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!isClientBrowser()) {
    return axiosInstance;
  }

  // Request interceptors
  axiosInstance.interceptors.request.use(function (config) {
    if (!storage) {
      return config;
    }

    // Request시 헤더에 Authorization 붙이기
    const token = trim(getAuthorization(storage));
    if (isEmpty(token ?? '')) {
      return config;
    }

    config.headers.Authorization = token;
    return config;
  });

  // Response intercepters
  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error: AxiosError) {
      const {
        maxRefresh = DEFAULT_REFRESH,
        refresh = 0,
        maxRetry = DEFAULT_RETRY,
        retry = 0,
      } = error.config?.[FYRI_NAMESPACE] ?? {};

      // 토큰 생성(로그인)요청의 경우 retry(google recaptcha 오류 발생으로 여러번 시도)
      if (
        equals(error?.config?.url, '/api/auth/token') &&
        lt(retry, maxRetry) &&
        String(error?.response?.status) !== '401'
      ) {
        // 기존 api 다시 호출
        const {config = {}} = error;
        config[FYRI_NAMESPACE] = {retry: retry + 1, maxRetry: DEFAULT_RETRY};
        return axiosInstance(config);
      }

      // 리프레쉬 회수 초과, 401 에러가 아닌 경우, 토큰 리프레쉬 요청인 경우 실패처리
      if (
        gte(refresh, maxRefresh) ||
        String(error?.response?.status) !== '401' ||
        equals(error?.config?.url, 'api/auth/token/refresh')
      ) {
        captureApiError(error);
        return Promise.reject(error);
      }

      // 토큰 리프레쉬 요청이 아닌 경우, 토큰 리프레쉬 요청
      const {refreshToken} = getToken(storage);
      const isSp = isSpLogined(storage);
      const isAndroid = isAndroidApp(storage);

      if (isEmpty(refreshToken ?? '')) {
        captureApiError(error);
        return Promise.reject(error);
      }

      const serverAxios = await instServerAxios();
      const {data} = await serverAxios
        .post('api/auth/token/refresh', {
          refreshToken: refreshToken,
          grantType: 'refresh_token',
          loginAgent: isAndroid ? 'APP' : isSp ? 'SP' : 'CP',
        })
        .catch(refreshError => {
          removeToken(storage);
          captureApiError(refreshError);
          throw refreshError;
        });

      // 새로운 토큰 저장
      setToken(storage, {
        tokenType: data?.tokenType,
        refreshToken: data?.refreshToken,
        accessToken: data?.accessToken,
        expires: data?.expires,
      });

      // 새로운 토큰으로 기존 api 다시 호출
      const {config = {}} = error;
      assocPath(
        ['headers', 'Authorization'],
        getAuthorization(storage),
        config
      );
      config[FYRI_NAMESPACE] = {
        refresh: refresh + 1,
        maxRefresh: DEFAULT_REFRESH,
      };
      return axiosInstance(config);
    }
  );

  // TODO : blob response type 일 경우 응답 오류 처리 필요
  axiosInstance.defaults.paramsSerializer = {
    serialize: function (params) {
      return qs.stringify(params, {arrayFormat: 'repeat'});
    },
  };

  return axiosInstance;
};

// Fyri(Nextjs Server) 서버로 요청하는 axios
let serverAxios: AxiosInstance;
export const instServerAxios = async function (): Promise<AxiosInstance> {
  if (!serverAxios) {
    serverAxios = createAxios(
      window.location.origin.toString(),
      instLocalStorage()
    );
  }
  return serverAxios;
};

// API 서버로 요청하는 axios
let cmsApiAxios: AxiosInstance;
export const instApiAxios = async function (): Promise<AxiosInstance> {
  if (!cmsApiAxios) {
    cmsApiAxios = createAxios(
      String(getEnv('URL_CMS_API')),
      instLocalStorage()
    );
  }
  return cmsApiAxios;
};

// ASIS Partner 서버로 요청하는 axios
let asisPartnerApiAxios: AxiosInstance;
export const instAsisAxios = async function (): Promise<AxiosInstance> {
  if (!asisPartnerApiAxios) {
    asisPartnerApiAxios = createAxios(
      String(getEnv('URL_ASIS_PARTNER')),
      instLocalStorage()
    );
  }
  return asisPartnerApiAxios;
};

// Google 서버로 요청하는 axios
let googleAxios: AxiosInstance;
export const instGoogleAxios = async function (): Promise<AxiosInstance> {
  if (!googleAxios) {
    googleAxios = axios.create({
      baseURL: String(getEnv('URL_GOOGLE')),
      timeout: 15000,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    googleAxios.interceptors.response.use(
      function (response) {
        return response;
      },
      async function (error: AxiosError) {
        await import('@sentry/nextjs').then(Sentry => {
          Sentry.withScope(scope => {
            scope.setLevel('info');
            scope.setFingerprint([
              'Google-api-failed',
              error.config?.url ?? '',
            ]);
            scope.setContext('Request', {
              url: error.config?.url,
              method: error.config?.method,
              data: error.config?.data,
              params: error.config?.params,
              headers: error.config?.headers,
            });
            scope.setContext('Response', {
              status: error.response?.status,
              statusText: error.response?.statusText,
              data: error.response?.data,
              headers: error.response?.headers,
            });
            error.message = `${error.config?.url ?? '???'} - ${error.message}`;
            scope.captureException(error);
          });
        });
        return Promise.reject(error);
      }
    );
  }
  return googleAxios;
};
