import {useQuery} from '@tanstack/react-query';
import {IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

export * from './useCreateOperator';
export * from './useDeleteOperator';
export * from './useQueryFindId';
export * from './useQueryFindPassword';
export * from './useQueryOperator';
export * from './useQueryOperatorValidate';
export * from './useUpdateOperator';

const QueryKey = 'GET:/user/operator/validate/username';

/**
 * ID 중복 검사 Query
 * @param args
 */
export const useQueryValidateId: IUseQuery5<
  boolean,
  {username?: string; operatorId?: number}
> = function (args = {}) {
  const {values, options = {}} = args;
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async () => {
      const axios = await instApiAxios();
      return axios
        .get<boolean>('/user/operator/validate/username', {
          params: values,
        })
        .then(response => response.data);
    },
    retry: false,
    refetchOnMount: false,
    ...options,
  });
};
