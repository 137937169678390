import {useQuery} from '@tanstack/react-query';
import type {IPartnerSiteMenuResponse, IUseQuery5} from '../../types';
import {instApiAxios} from '../_axios';

const QueryKey = 'GET:/partner/menu';

/**
 * 파트너사이트 SNB 메뉴 정보 가져오기 Query
 *
 * @param args
 */
export const useQueryPartnerSiteMenu: IUseQuery5<IPartnerSiteMenuResponse> =
  function ({options} = {}) {
    return useQuery({
      queryKey: [QueryKey],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get<IPartnerSiteMenuResponse>('/partner/menu', {})
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
