import {useMutation} from '@tanstack/react-query';
import {pick} from 'ramda';
import type {IAuthor, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';
import {instQueryClient} from '../../_queryClient5';
import AuthorListQueryKey from './useInfQueryAuthorList';
import AuthorQueryKey from './useQueryAuthor';

/**
 * 작가 수정
 */
export const useUpdateAuthor: IUseMutation5<
  IAuthor,
  Partial<IAuthor>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async function (author) {
      const axios = await instApiAxios();

      return axios
        .put(`/product/author/${author.authorUid}`, {
          ...pick(['authorName', 'description'], author),
          foreignName: author.foreignAuthorName,
        })
        .then(response => response.data);
    },
    onSuccess: () => {
      const queryClient = instQueryClient();
      queryClient.invalidateQueries({queryKey: [AuthorQueryKey]});
      queryClient.invalidateQueries({queryKey: [AuthorListQueryKey]});
    },
    ...options,
  });
};
