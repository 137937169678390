import {useMutation} from '@tanstack/react-query';
import type {AxiosResponse} from 'axios';
import type {ISearchType, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

export interface QueryDownloadSeriesListValues {
  searchType?: ISearchType;
  searchFiled?: string;
  charSet?: string;
  publisherUid?: number;
}

/**
 * 작품 목록 다운로드
 * @param options
 * @returns
 */
export const useDownloadSeriesList: IUseMutation5<
  boolean,
  QueryDownloadSeriesListValues
> = function (args = {}) {
  const {options} = args;

  return useMutation({
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      return axios
        .get('/product/series/isbn/download', {
          params: values,
          responseType: 'blob',
          timeout: 0,
        })
        .then(responseToDownload)
        .then(response => response.data);
    },
    ...options,
  });
};

/**
 * 다운로드 헨들링
 * @param response
 */
function responseToDownload(response: AxiosResponse) {
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // get file name
  const contentDisposition = response.headers['content-disposition'];
  let fileName = 'file.csv';
  if (contentDisposition) {
    const [fileNameMatch] = contentDisposition
      .split(';')
      .filter((str: string) => str.includes('filename='));
    if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
    fileName = decodeURI(fileName);
  }

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);

  return response;
}
