import {useMutation} from '@tanstack/react-query';
import type {IUseMutation5} from '../../types';
import {getToken, instApiAxios, instLocalStorage} from '../_axios';

export const useDeleteToken: IUseMutation5 = function (args = {}) {
  const storage = instLocalStorage();
  const {options} = args;

  return useMutation({
    mutationFn: async function () {
      const token = getToken(storage);
      const axios = await instApiAxios();
      return await axios.delete<boolean>('/auth/token', {
        params: {refreshToken: token.refreshToken},
      });
    },
    ...options,
  });
};
