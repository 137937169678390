import {useQuery} from '@tanstack/react-query';
import type {IPartnerServiceInfo, IUseQuery5} from '../../types';
import {instApiAxios} from '../_axios';

const QueryKey = 'GET:/gateway/monitor/info';

/**
 * CMS 게이트웨이 서비스(피카츄) 정보 가져오기 Query
 *
 * @param args
 */
export const useQueryCmsGatewaySvcInfo: IUseQuery5<IPartnerServiceInfo> =
  function ({options} = {}) {
    return useQuery({
      queryKey: [QueryKey],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get<IPartnerServiceInfo>('/gateway/monitor/info', {})
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
