import {isNilEmpty} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import RSA_PUBLIC_JSON from '../../../.secret/rsa_public_key.json';
import {IOperator, IUseMutation5} from '../../../types';
import {encryptRSA} from '../../../utils/crypto';
import {instServerAxios} from '../../_axios';

const MutationKey = 'POST:api/user/operator';

export interface CreateOperatorValues {
  publisherUid: number;
  operator: Pick<
    IOperator,
    'email' | 'username' | 'password' | 'realName' | 'cellphone'
  >;
  token: string;
}

/**
 * Operator 생성 Mutation
 *
 * @param options
 * @param dependencies
 */
export const useCreateOperator: IUseMutation5<IOperator, CreateOperatorValues> =
  function (args = {}) {
    const {options} = args;
    return useMutation({
      mutationKey: [MutationKey],
      mutationFn: async values => {
        const apiValues = {
          ...values.operator,
          publisherUid: values.publisherUid,
          token: values.token,
        };
        const axios = await instServerAxios();
        return axios
          .post<IOperator>('api/user/operator', {
            ...apiValues,
            password: isNilEmpty(apiValues?.password)
              ? undefined
              : encryptRSA(RSA_PUBLIC_JSON.pem, apiValues?.password ?? ''),
          })
          .then(response => response.data);
      },
      ...options,
    });
  };

export default MutationKey;
