import {useMutation} from '@tanstack/react-query';
import {IOperator, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * Operator 권환 수정
 * @param args
 * @returns
 */
export const useUpdateOperatorRole: IUseMutation5<
  IOperator,
  {operatorId?: number; roleUid?: number}
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async (values: {operatorId: number; roleUid: number}) => {
      const axios = await instApiAxios();
      return axios
        .put<IOperator>(`/user/operator/${values.operatorId}/role`, {
          roleUid: values.roleUid,
        })
        .then(response => response.data);
    },
    ...options,
  });
};
