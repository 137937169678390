import {pick, prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ISeriesBm, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

const MutationKey = 'PUT:/product/series/{seriesId}/bm';

/**
 * 작품 BM정보 변경
 * @param options
 * @returns
 */
export const useUpdateSeriesBm: IUseMutation5<ISeriesBm, ISeriesBm> = function (
  args = {}
) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const seriesId = prop('seriesId', values);
      const axios = await instApiAxios();
      const result = await axios
        .put<ISeriesBm>(
          `/product/series/${seriesId}/bm`,
          filterSeriesBmValues(values),
          {
            headers: {'Content-Type': 'application/json;'},
          }
        )
        .then(response => response.data);

      return result;
    },
    ...options,
  });
};

/**
 * bm update 시 전달해야할 프로퍼티 필터링
 *
 * @param seriesBm
 * @returns
 */
function filterSeriesBmValues(seriesBm: ISeriesBm) {
  const pickArray: [keyof ISeriesBm, ...(keyof ISeriesBm)[]] = ['bmType'];

  switch (seriesBm.bmType) {
    case 'T':
      if (seriesBm.useAllFree) {
        seriesBm = {
          ...seriesBm,
          useWaitFree: false,
        };
      }

      if (seriesBm.useWaitFree) {
        seriesBm = {
          ...seriesBm,
          useAllFree: false,
        };

        pickArray.push('ticketChargingTimeInMinute', 'singleBlockCount');
      }

      pickArray.push('useAllFree', 'useWaitFree');
      break;
    case 'M':
      if (seriesBm.useWaitFree) {
        pickArray.push('ticketChargingTimeInMinute', 'singleBlockCount');
      }

      pickArray.push('useWaitFree', 'previewSectionStartOrder');
      break;
  }

  return pick(pickArray, seriesBm);
}
