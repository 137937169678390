import {useMutation} from '@tanstack/react-query';
import {
  equals,
  includes,
  isEmpty,
  isNil,
  mapObjIndexed,
  mergeDeepRight,
  mergeLeft,
  pickBy,
  pipe,
  replace,
  toPairs,
} from 'ramda';
import RSA_PUBLIC_JSON from '../../../.secret/rsa_public_key.json';
import {IOperator, IPublisher, IUseMutation5} from '../../../types';
import {encryptRSA} from '../../../utils/crypto';
import {pairsToFormData} from '../../../utils/network';
import {instServerAxios} from '../../_axios';

interface UpdatePublisherValues {
  publisher?: Partial<IPublisher>;
  operator?: Partial<
    Pick<
      IOperator,
      'realName' | 'cellphone' | 'email' | 'id' | 'currPwd' | 'newPwd'
    >
  >;
}

/**
 * Publisher 수정 mutation
 * @param args
 */
export const useUpdatePublisher: IUseMutation5<
  IPublisher,
  UpdatePublisherValues
> = function (args = {}) {
  const {options} = args;

  return useMutation({
    mutationFn: async values => {
      const axios = await instServerAxios();

      // publisehr, operator 암호화 및 병합
      const encryptedOperator = mergeLeft(
        {
          currPwd: encryptRSA(
            RSA_PUBLIC_JSON.pem,
            values.operator?.currPwd ?? ''
          ),
          newPwd: encryptRSA(
            RSA_PUBLIC_JSON.pem,
            values.operator?.newPwd ?? ''
          ),
        },
        values.operator ?? {}
      );
      const mergedValues = mergeDeepRight(
        values.publisher ?? {},
        encryptedOperator
      ) as IPublisher & IOperator;
      const formData: FormData = pipe(
        // Nil제거, 빈스트링 제거, operator.id 제거(operatorUid로 변경해서 넣어줘야함)
        pickBy((value, key) => {
          if (equals(key, 'id')) {
            return false;
          }
          if (equals(typeof value, 'string')) {
            return !isEmpty(value) && !isNil(value);
          }
          return !isNil(value);
        }),
        // 전화번호 스트링에서 '-'제거
        mapObjIndexed((value: any, key) => {
          if (includes(key, ['cellphone', 'phone'])) {
            return replace(/-/g, '', value);
          }
          return value;
        }),
        // [key, value] 페어 형태로 전환
        toPairs,
        // FormData로 리듀스
        pairsToFormData
      )(mergedValues);
      formData.append('operatorUid', String(values?.operator?.id));

      const result = await axios
        .put<IPublisher>(
          `api/user/publisher/${values?.publisher?.id}`,
          formData,
          {
            headers: {'Content-Type': 'multipart/form-data;'},
          }
        )
        .then(response => response.data);

      return result;
    },
    ...options,
  });
};
