import {useQuery} from '@tanstack/react-query';
import {flatten, is} from 'ramda';
import {IOperator, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

export * from './useQueryFindId';
export * from './useQueryFindPassword';

const QueryKey = 'GET:/user/operator/${operatorUid}';

/**
 * Operator 정보 가져오기 Query
 * @param args
 */
export const useQueryOperator: IUseQuery5<IOperator, {operatorId?: number}> =
  function (args = {}) {
    const {values, options} = args;

    return useQuery({
      queryKey: [
        QueryKey,
        ...flatten(options?.queryKey ? [options?.queryKey] : []),
      ],
      queryFn: async function () {
        const axios = await instApiAxios();

        return axios
          .get(`/user/operator/${values?.operatorId}`)
          .then(response => response.data);
      },
      enabled: is(Number, values?.operatorId),
      ...options,
    });
  };

export default QueryKey;
