import {prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ISetSeries, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'PUT:product/series/{seriesId}/set/{setSeriesUid}/approve';

export interface UpdateSetSeriesApproveValues extends Partial<ISetSeries> {
  seriesId?: number;
  setSeriesUid?: number;
}

/**
 * 세트상품 승인
 *
 */
export const useUpdateSetSeriesApprove: IUseMutation5<
  boolean,
  Partial<UpdateSetSeriesApproveValues>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [QueryKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const seriesId = prop('seriesId', values);
      const setSeriesUid = prop('setSeriesUid', values);

      return await axios
        .put(`/product/series/${seriesId}/set/${setSeriesUid}/approve`, {
          params: values,
        })
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
