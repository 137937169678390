import {useMutation} from '@tanstack/react-query';
import type {ISeriesNoticeDTO, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';
import {instQueryClient} from '../../_queryClient5';
import SeriesNoticeListQueryKey from '../series/useQuerySeriesNoticeList';
import {filterSeriesNoticeValues} from './_utils';

const MutationKey = 'POST:/product/notice';

/**
 * 작품 공지사항 생성
 *
 */
export const useCreateSeriesNotice: IUseMutation5<
  ISeriesNoticeDTO,
  Partial<ISeriesNoticeDTO>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();

      return await axios
        .post<ISeriesNoticeDTO>(
          `/product/notice`,
          filterSeriesNoticeValues(values)
        )
        .then(response => response.data);
    },
    onSuccess: () => {
      const queryClient = instQueryClient();
      queryClient.invalidateQueries({queryKey: [SeriesNoticeListQueryKey]});
    },
    ...options,
  });
};

export default MutationKey;
