import {useQuery} from '@tanstack/react-query';
import {filter, is, isEmpty, isNil, omit, pick, pipe} from 'ramda';
import {IApiResponse, IOperator, IPagination, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/user/publisher/{publisherUid}/operators';

export type QueryOperatorListResponse = IApiResponse<{operators?: IOperator[]}>;

export interface QueryoperatorListValue extends Partial<IPagination> {
  publisherUid?: number;
  operatorRoleTypes?: string;
  realName?: string;
  username?: string;
}

/**
 * 발행처의 operator 목록 조회
 * @param args
 * @returns
 */
export const useQueryOperatorList: IUseQuery5<
  QueryOperatorListResponse,
  QueryoperatorListValue
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async () => {
      const axios = await instApiAxios();
      const params = pipe(
        _values => omit(['publisherUid'], _values),
        pick([
          'realName',
          'username',
          'operatorRoleTypes',
          'paginationOffset',
          'paginationLimit',
        ]),
        filter((value: any) => {
          if (is(Number, value)) {
            return !isNil(value);
          }
          return !isNil(value) && !isEmpty(value);
        })
      )(values ?? {});

      return axios
        .get<QueryOperatorListResponse>(
          `/user/publisher/${values?.publisherUid}/operators`,
          {
            params,
          }
        )
        .then(response => response.data);
    },
    enabled: is(Number, values?.publisherUid),
    ...options,
  });
};

export default QueryKey;
