import {is, prop} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {ISetSeries, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/series/{seriesId}/set/{setSeriesUid}';

interface QuerySetSeriesValues extends Partial<ISetSeries> {
  seriesId?: number;
  setSeriesUid?: number;
}

/**
 * 세트상품 정보 조회
 *
 */
export const useQuerySetSeries: IUseQuery5<
  ISetSeries,
  Partial<QuerySetSeriesValues>
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      const seriesId = prop('seriesId', values ?? {});
      const setSeriesUid = prop('setSeriesUid', values ?? {});

      return axios
        .get(`/product/series/${seriesId}/set/${setSeriesUid}`)
        .then(response => response.data);
    },
    enabled:
      is(Number, prop('seriesId', values ?? {})) &&
      is(Number, prop('setSeriesUid', values ?? {})),
    ...options,
  });
};

export default QueryKey;
