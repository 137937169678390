import {useMutation} from '@tanstack/react-query';
import {pick} from 'ramda';
import type {IAuthor, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';
/**
 * 작가 등록
 */
export const useCreateAuthor: IUseMutation5<
  IAuthor,
  Partial<IAuthor>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async function (author) {
      const axios = await instApiAxios();

      return axios
        .post('/product/author', {
          ...pick(['authorName', 'description'], author),
          foreignName: author.foreignAuthorName,
        })
        .then(response => response.data);
    },
    ...options,
  });
};
