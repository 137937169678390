import {useQuery} from '@tanstack/react-query';
import type {ISeriesLabelDTO, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/property/series/label';

/**
 * 작품 레이블 DTO정보 조회
 * @param values
 * @param options
 * @returns
 */
export const useQuerySeriesLabelDTO: IUseQuery5<
  ISeriesLabelDTO[],
  {publisherUid?: number; seriesId?: number}
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get('/product/property/series/label', {params: values})
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
