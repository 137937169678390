import {useQuery} from '@tanstack/react-query';
import {ISearchPublisher, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/user/publisher/list';

/**
 * Publisher 목록 가져오기
 */
interface IUseQueryPublisherListValues {
  status: string;
  displayName?: string;
  username?: string;
}

export const useQueryPublisherList: IUseQuery5<
  Array<ISearchPublisher>,
  IUseQueryPublisherListValues
> = function (args = {}) {
  const {values, options} = args;
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async () => {
      const axios = await instApiAxios();
      return axios
        .get('/user/publisher/list', {params: values})
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
