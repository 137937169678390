import {pick, prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

const MutationKey = 'PUT:/product/series/{seriesId}/price';

export interface UpdateSeriesSinglesPriceValues {
  seriesId: number;
  singleRetailPriceList?: {
    singleId?: number;
    rentalRetailPrice?: number;
    savedRetailPrice?: number;
  }[];
}

export interface UpdateSeriesPriceResponse {
  failedCount?: number;
  failedSingleList?: {reason?: string; singleIdList?: number[]}[];
  totalCount?: number;
}

/**
 * 작품 판매설정 정보 변경
 * @param options
 * @returns
 */
export const useUpdateSeriesSinglesPrice: IUseMutation5<
  UpdateSeriesPriceResponse,
  UpdateSeriesSinglesPriceValues
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const seriesId = prop('seriesId', values);

      const axios = await instApiAxios();
      const result = await axios
        .put<UpdateSeriesPriceResponse>(
          `/product/series/${seriesId}/single/price`,
          pick(['singleRetailPriceList'], values),
          {
            headers: {'Content-Type': 'application/json;'},
            timeout: 0,
          }
        )
        .then(response => response.data);

      return result;
    },
    ...options,
  });
};

export default MutationKey;
