import {prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ISeriesForm, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * 작품 삭제
 */
export const useDeleteSeries: IUseMutation5<
  {seriesId: number},
  Partial<ISeriesForm>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const seriesId = prop('seriesId', values);
      return axios
        .delete(`/product/series/${seriesId}`)
        .then(response => response.data);
    },
    ...options,
  });
};
