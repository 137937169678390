import {prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ISeriesNoticeDTO, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';
import {instQueryClient} from '../../_queryClient5';
import SeriesNoticeListQueryKey from '../series/useQuerySeriesNoticeList';
import {filterSeriesNoticeValues} from './_utils';

const MutationKey = 'PUT:/product/notice/{noticeUid}';

/**
 * 작품 공지사항 업데이트
 *
 */
export const useUpdateSeriesNotice: IUseMutation5<
  ISeriesNoticeDTO,
  Partial<ISeriesNoticeDTO>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const noticeUid = prop('noticeUid', values);

      return await axios
        .put<ISeriesNoticeDTO>(
          `/product/notice/${noticeUid}`,
          filterSeriesNoticeValues(values)
        )
        .then(response => response.data);
    },
    onSuccess: () => {
      const queryClient = instQueryClient();
      queryClient.invalidateQueries({queryKey: [SeriesNoticeListQueryKey]});
    },
    ...options,
  });
};
export default MutationKey;
