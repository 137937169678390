import {useMutation} from '@tanstack/react-query';
import type {ISlideType, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * 슬라이드 다운로드 키를 생성합니다.
 * @param values
 * @param options
 * @returns
 */
export const useCreateSlideDownloadKey: IUseMutation5<
  {downloadKey: string; slideUid: number; slideType: ISlideType},
  {slideUid: number}
> = function ({options} = {}) {
  return useMutation({
    mutationFn: async function ({slideUid}) {
      const axios = await instApiAxios();
      return axios
        .post(`/product/contents/slide/${slideUid}/download-key`)
        .then(response => response.data);
    },
    ...options,
  });
};
