import {useQuery} from '@tanstack/react-query';
import {is} from 'ramda';
import {ApiError, IPublisher, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/user/publisher/${publisherUid}';

/**
 * Publisher 정보 가져오기 Query
 *
 * @param args
 */
export const useQueryPublisher: IUseQuery5<
  IPublisher,
  {publisherUid?: number}
> = function (args = {}) {
  const {values, options} = args;

  return useQuery<IPublisher, ApiError>({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get<IPublisher>(`/user/publisher/${values?.publisherUid}`)
        .then(response => response.data);
    },
    enabled: is(Number, values?.publisherUid),
    ...options,
  });
};

export default QueryKey;
