import {useQuery} from '@tanstack/react-query';
import type {IFilter, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/property/filter/category';

/**
 * 작품 카테고리 필터 조회(작품목록)
 * @param options
 * @returns
 */
export const useQuerySeriesCategoryFilter: IUseQuery5<IFilter[]> = function ({
  options,
} = {}) {
  return useQuery({
    queryKey: [QueryKey],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get('/product/property/filter/category')
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
