import {assoc, pick} from '@kakaoent/ops-design';
import moment from 'moment';
import type {ISeriesNoticeDTO} from '../../../types';

export function filterSeriesNoticeValues(
  seriesNotice: Partial<ISeriesNoticeDTO & {isCheckHomeExposed?: boolean}>
) {
  let newResult: Partial<ISeriesNoticeDTO>;
  newResult = pick(
    [
      'seriesId',
      'noticeType',
      'noticeHomeStatus',
      'noticeTitle',
      'noticeDescription',
      'isNoticeReservedExposureStart',
      'isNoticeReservedExposureEnd',
      'isNoticeHomeReservedExposureStart',
      'isNoticeHomeReservedExposureEnd',
    ],
    seriesNotice
  );

  if (seriesNotice.isCheckHomeExposed) {
    if (seriesNotice.isNoticeHomeReservedExposureStart) {
      newResult = assoc(
        'noticeHomeExposeStartDt',
        moment(seriesNotice.noticeHomeExposeStartDt).toDate(),
        newResult
      );
    }

    if (seriesNotice.isNoticeHomeReservedExposureEnd) {
      newResult = assoc(
        'noticeHomeExposeEndDt',
        moment(seriesNotice.noticeHomeExposeEndDt).toDate(),
        newResult
      );
    }
  }

  if (seriesNotice.isNoticeReservedExposureStart) {
    newResult = assoc(
      'noticeExposeStartDt',
      moment(seriesNotice.noticeExposeStartDt).toDate(),
      newResult
    );
  }

  if (seriesNotice.isNoticeReservedExposureEnd) {
    newResult = assoc(
      'noticeExposeEndDt',
      moment(seriesNotice.noticeExposeEndDt).toDate(),
      newResult
    );
  }

  return newResult;
}
