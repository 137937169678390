import {isNil} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {ApiError, ISeriesBm, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/series/{seriesId}/bm';

export interface QuerySeriesBmValues {
  seriesId: number;
}

/**
 * 작품 BM정보 조회
 * @param {Object} params
 * @param {QuerySeriesBmValues} params.values
 * @param {UseQueryOptions<ISeriesBm, ApiError, ISeriesBm, QueryKey>} params.options - 이용권 사용 현황 통계 조회 쿼리 옵션
 * @returns QueryObserverResult
 */
export const useQuerySeriesBm: IUseQuery5<ISeriesBm, QuerySeriesBmValues> =
  function ({values, options} = {}) {
    return useQuery<ISeriesBm, ApiError>({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get(`product/series/${values?.seriesId}/bm`)
          .then(response => response.data);
      },
      enabled: !isNil(values?.seriesId),
      ...options,
    });
  };

export default QueryKey;
