import {useQuery} from '@tanstack/react-query';
import {is} from 'ramda';
import {IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueyrKey = 'GET:/user/publisher/${publisherUid}/business-register/image';

/**
 * 사업자 등록증 사본 이미지 조회 Query
 * @param args
 * @returns
 */
export const useQueryBusinessRegImg: IUseQuery5<
  string,
  {publisherUid?: number}
> = function (args = {}) {
  const {values, options} = args;
  return useQuery({
    queryKey: [QueyrKey, values?.publisherUid],
    queryFn: async () => {
      const axios = await instApiAxios();
      return axios
        .get<string>(
          `/user/publisher/${values?.publisherUid}/business-register/image`
        )
        .then(response => response.data);
    },
    enabled: is(Number, values?.publisherUid),
    ...options,
  });
};
