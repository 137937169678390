import {useQuery} from '@tanstack/react-query';
import type {
  IApiResponse,
  ISeries,
  ISeriesBm,
  IUseQuery5,
} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/series';

export type SeiresWithBm = ISeries & {
  businessModelInfo?: Pick<ISeriesBm, 'bmType' | 'useWaitFree'> & {
    bmName: string;
  };
};
export interface QuerySeriesList {
  series: SeiresWithBm[];
}

/**
 * 작품 목록 조회
 * @param values
 * @param options
 * @returns
 */
export const useQuerySeriesList: IUseQuery5<IApiResponse<QuerySeriesList>> =
  function ({values, options} = {}) {
    return useQuery({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get('/product/series', {
            params: values,
          })
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
