import {useQuery} from '@tanstack/react-query';
import {is, prop} from 'ramda';
import type {ApiError, ISeries, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/series/{seriesId}';

export interface QuerySeriesValues {
  seriesId?: number;
}

/**
 * 작품 정보 조회
 * @param {Object} params
 * @param {QuerySeriesValues} params.values
 * @param {UseQueryOptions<ISeries, ApiError, ISeries, QueryKey>} params.options
 * @returns QueryObserverResult
 */
export const useQuerySeries: IUseQuery5<ISeries, QuerySeriesValues> =
  function ({values, options} = {}) {
    return useQuery<ISeries, ApiError>({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        const seriesId = prop('seriesId', values ?? {});
        return axios
          .get(`product/series/${seriesId}`)
          .then(response => response.data);
      },
      enabled: is(Number, prop('seriesId', values ?? {})),
      ...options,
    });
  };

export default QueryKey;
