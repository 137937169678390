import {equals, isNilEmpty, trim} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import {jwtDecode} from 'jwt-decode';
import type {ISession, IUseQuery5, IUserToken} from '../../types';
import {isAppUserAgent} from '../../utils/network';
import {getAuthorization, instApiAxios, instLocalStorage} from '../_axios';

const QueryKey = 'GET:/auth/userinfo';

export const useQueryUserInfo: IUseQuery5<ISession> = function ({
  options,
} = {}) {
  const storage = instLocalStorage();

  return useQuery({
    queryKey: [QueryKey],
    queryFn: async function () {
      if (isNilEmpty(trim(getAuthorization(storage)))) {
        return Promise.reject('token expired');
      }

      // 서버로부터 유저 토큰 정보 가져오기
      const axios = await instApiAxios();
      const response = await axios.get('/auth/userinfo');
      const userToken = jwtDecode<IUserToken>(response.data.userInfoToken);

      // 유저 토큰 정보를 토대로 상태 및 권한 체크
      const [authFailure, forcedRedirectUrl] = checkPublisherState(userToken);

      if (!isNilEmpty(authFailure)) {
        return Promise.reject({
          response: {
            data: {
              customDetail: authFailure,
            },
          },
        });
      }

      return {
        ...userToken,
        authFailure,
        forcedRedirectUrl,
      };
    },
    refetchOnMount: false,
    refetchInterval: 300000,
    refetchOnWindowFocus: false,
    ...options,
  });
};

/**
 * SP 및 발행처 상태 검사 후, 세션연결 가능한지 검사
 *
 * @param userToken
 * @returns
 */
function checkPublisherState(
  userToken: IUserToken
): [authFailure?: string, forcedRedirectUrl?: string] {
  if (isNilEmpty(userToken)) {
    return [undefined, undefined];
  }

  // SP이면 통과
  if (userToken.customerInfo?.spAccount) {
    return checkUserToken(userToken);
  }

  // CP 이면 발행처 상태 및 권한 체크
  const publisherState = userToken.customerInfo.publisherState;
  switch (publisherState) {
    case undefined:
    case null:
    case 'PS02':
    case 'PS03':
    case 'PS11':
    case 'PS14':
    case 'PS99': {
      return ['로그인 할 수 없습니다.'];
    }
    case 'PS01': {
      // 미승인(PS01)
      if (isAppUserAgent()) {
        return ['계정 승인 대기 상태입니다.'];
      }
      return [undefined, '/user/change/userinfo'];
    }
    case 'PS13': {
      // 심사반려(PS13)
      if (isAppUserAgent()) {
        return ['계정 심사반려 상태입니다. 웹에서 정보를 수정해주세요.'];
      }
      return [undefined, '/user/change/userinfo'];
    }
    case 'PS12': {
      // 심사중(PS12)
      return ['승인 전입니다.\n승인까지 영업일 기준으로 1~3일 소요됩니다.'];
    }
    case 'PS51': {
      // VOD CP(PS51)
      if (isAppUserAgent()) {
        return ['VOD 계정은 웹을 이용해주세요.'];
      }
      return [undefined, '/user/change/userinfo'];
    }
    default: {
      // 탈퇴신청(PS04) | 휴폐업(PS15) | 일반CP(PS20) | VOD_DKS(PS52) | VOD_SP(PS53)
      return checkUserToken(userToken);
    }
  }
}

function checkUserToken(
  userToken: IUserToken
): [authFailure?: string, forcedRedirectUrl?: string] {
  // 휴면계정의 경우 로그인은 되나, 휴면계정활성화 화면으로 강제 이동
  if (userToken.customerInfo?.dormant) {
    return [undefined, '/user/dormant'];
  }

  // operatorRole 이 없는 경우 로그인 불가
  const operatorRole = userToken.customerInfo?.operatorRole;
  if (isNilEmpty(operatorRole) || equals(operatorRole, 'NONE')) {
    return [
      '아직 권한이 부여되지 않아서 로그인 할 수 없습니다.\n관리자에게 권한을 요청해주세요.',
    ];
  }

  // 채팅소설작가, 정산 권한인 경우 파트너앱 로그인 불가
  const isTalkAuthor = equals(operatorRole, 'TALK_AUTHOR');
  if (isTalkAuthor || equals(operatorRole, 'BALANCE')) {
    if (isAppUserAgent()) {
      return ['권한이 없습니다.\n파트너사이트 웹을 이용해주세요.'];
    }

    if (isTalkAuthor) {
      return [undefined, '/user/change/userinfo'];
    }
  }

  if (
    isAppUserAgent() &&
    equals(userToken.customerInfo.publisherState, 'PS15')
  ) {
    return ['휴폐업 상태입니다.'];
  }
  return [];
}

export default QueryKey;
