import {isNil} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {ISeriesNoticeDTO, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/notice/{noticeUid}';
/**
 * 작품 공지사항 상세 조회
 */
export const useQuerySeriesNotice: IUseQuery5<
  ISeriesNoticeDTO,
  IQuerySeriesNoticeValues
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      const noticeUid = values?.noticeUid;

      return axios
        .get(`/product/notice/${noticeUid}`, {
          params: values,
        })
        .then(response => response.data);
    },
    enabled: !isNil(values?.noticeUid),
    ...options,
  });
};

interface IQuerySeriesNoticeValues {
  noticeUid: number;
}

export default QueryKey;
