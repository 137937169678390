import {prop} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import {IApiResponse, ISeriesNotice, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/notice';

/**
 * 작품 공지사항 목록 조회
 *
 * @param values
 * @param options
 * @returns
 */
export const useQuerySeriesNoticeList: IUseQuery5<IApiResponse<ISeriesNotice>> =
  function ({values, options} = {}) {
    return useQuery({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        const seriesId = prop('seriesId', values);

        return axios
          .get(`/product/series/${seriesId}/notice`, {
            params: values,
          })
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
