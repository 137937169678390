import {useQuery} from '@tanstack/react-query';
import type {IFilter, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/property/filter/series/businessModel';

/**
 * 작품 판매정책 필터 정보 조회
 * @param options
 * @returns
 */
export const useQuerySeriesBmFilter: IUseQuery5<IFilter[]> = function ({
  options,
} = {}) {
  return useQuery({
    queryKey: [QueryKey],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get('/product/property/filter/series/businessModel')
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
