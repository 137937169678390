import {prop} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/series/{seriesId}/check/require-info';

/**
 * 작품 필수정보 확인
 * TODO: 포팅 1단계에서만 사용하며, 이후 포팅이 완료되면 제거합니다.
 */
export const useQuerySeriesRequireInfo: IUseQuery5<void, {seriesId?: number}> =
  function (args = {}) {
    const {values = {}, options} = args;

    return useQuery({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        const seriesId = prop('seriesId', values);
        return axios
          .get(`/product/series/${seriesId}/check/require-info`, {
            params: values,
          })
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
