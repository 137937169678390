import {useQuery} from '@tanstack/react-query';
import {IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/user/publisher/validate/displayName';
/**
 * 발행처명 중복 검사 Query
 *
 * @param args
 * @returns
 */
export const useQueryValidateDisplayName: IUseQuery5<
  boolean,
  {displayName?: string; publisherUid?: number}
> = function ({values, options = {}} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async () => {
      const axios = await instApiAxios();
      return axios
        .get<boolean>('/user/publisher/validate/displayName', {
          params: values,
        })
        .then(response => response.data);
    },
    retry: false,
    refetchOnMount: false,
    ...options,
  });
};
