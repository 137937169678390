import {useMutation} from '@tanstack/react-query';
import type {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * 작품 목록 업로드
 * @param options
 * @returns
 */
export const useUploadSeriesList: IUseMutation5<boolean, {file: File}> =
  function (args = {}) {
    const {options} = args;

    return useMutation({
      mutationFn: async function (values) {
        const axios = await instApiAxios();
        const formData: FormData = new FormData();

        formData.append('uploadFile', values.file);

        return axios
          .post('/product/series/isbn/upload', formData, {
            headers: {'Content-Type': 'multipart/form-data;'},
            timeout: 0,
          })
          .then(response => response.data);
      },
      ...options,
    });
  };
