import {useMutation} from '@tanstack/react-query';
import {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * 탈퇴 신청 Mutation
 * @param args
 * @returns
 */
export const useUpdateWithdrawPublisher: IUseMutation5<
  boolean,
  {
    currPwd?: string;
    publisherUid?: number;
  }
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async values => {
      const axios = await instApiAxios();
      return axios
        .put<boolean>(
          `/user/publisher/${values.publisherUid}/withdraw/request`,
          {currPwd: values.currPwd}
        )
        .then(response => response.data);
    },
    ...options,
  });
};
