import {useQuery} from '@tanstack/react-query';
import type {ApiError, ISeriesTalkViewerInfo, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/property/series/talk-story';

export interface IQueryTalkstoryValues {
  publisherUid?: number;
  talkStoryId?: number;
}

/**
 * 톡뷰어 매핑 정보 조회
 * @param {Object} params
 * @param {IQueryTalkstoryValues} params.values - 톡뷰어 매핑 정보 조회 값
 * @param {UseQueryOptions} params.options - 톡뷰어 매핑 정보 조회 쿼리 옵션
 * @returns QueryObserverResult
 */
export const useQuerySeriesTalkstory: IUseQuery5<
  Array<ISeriesTalkViewerInfo>,
  IQueryTalkstoryValues
> = function ({values, options} = {}) {
  return useQuery<Array<ISeriesTalkViewerInfo>, ApiError>({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get('/product/property/series/talk-story', {params: values})
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
