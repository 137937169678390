import {useMutation} from '@tanstack/react-query';
import {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * Operator 가입용 Secured URL 생성 및 메일 발송
 * @param args
 * @returns
 */
export const useCreateOperatorGuidanceMail: IUseMutation5<
  boolean,
  {publisherUid: number; email: string}
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async values => {
      const axios = await instApiAxios();
      const formData = new FormData();
      formData.append('operatorSignUpEmail', values.email);

      return axios
        .post<boolean>(
          `/user/publisher/${values.publisherUid}/operatorGuidanceMail`,
          formData,
          {headers: {'Content-Type': 'multipart/form-data'}}
        )
        .then(response => response.data);
    },
    ...options,
  });
};
