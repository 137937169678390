import {useQuery} from '@tanstack/react-query';
import type {ISetSeriesFilterState, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/property/set/state';

/**
 * 세트상품 목록 판매상태 필터 조회
 * @param options
 * @returns
 */
// state
export const useQuerySetSeriesStateFilter: IUseQuery5<ISetSeriesFilterState[]> =
  function ({options} = {}) {
    return useQuery({
      queryKey: [QueryKey],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get('/product/property/set/state')
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
