import {useQuery} from '@tanstack/react-query';
import type {IFilter, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/property/filter/series/onIssue';

/**
 * 작품 연재 주기 필터 조회
 * @param options
 * @returns
 */
export const useQuerySeriesOnissueFilter: IUseQuery5<IFilter[]> = function ({
  options,
} = {}) {
  return useQuery({
    queryKey: [QueryKey],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get('/product/property/filter/series/onIssue')
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
