import {prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ISetSeries, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

const MutationKey = 'DELETE:product/series/{seriesId}/set/{setSeriesUid}';

export interface DeleteSetSeriesValues extends Partial<ISetSeries> {
  seriesId?: number;
  setSeriesUid?: number;
}

/**
 * 세트상품 삭제
 *
 */
export const useDeleteSetSeries: IUseMutation5<
  boolean,
  Partial<DeleteSetSeriesValues>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const seriesId = prop('seriesId', values);
      const setSeriesUid = prop('setSeriesUid', values);

      return await axios
        .delete(`/product/series/${seriesId}/set/${setSeriesUid}`, {
          params: values,
        })
        .then(response => response.data);
    },
    ...options,
  });
};
