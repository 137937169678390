import {isNilEmpty} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {IIsbn, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/external/seoji/book/isbn';

export interface QueryBookIsbnValues {
  isbn?: string;
}

/**
 * 면세코드 조회
 * @param {Object} params
 * @param {QueryBookIsbnValues} params.values - 톡뷰어 매핑 정보 조회 값
 * @param {UseQueryOptions} params.options - 톡뷰어 매핑 정보 조회 쿼리 옵션
 * @returns QueryObserverResult
 */
export const useQueryBookIsbn: IUseQuery5<IIsbn, QueryBookIsbnValues> =
  function ({values, options} = {}) {
    return useQuery({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get('/product/external/seoji/book/isbn', {params: values})
          .then(response => response.data);
      },
      enabled: !isNilEmpty(values?.isbn),
      ...options,
    });
  };

export default QueryKey;
