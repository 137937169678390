import {useMutation} from '@tanstack/react-query';
import {IOperator, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

const MutationKey = 'DELETE:/user/operator';

/**
 * Operator 계정 삭제
 * @param args
 * @returns
 */
export const useDeleteOperator: IUseMutation5<IOperator, {operatorId: number}> =
  function (args = {}) {
    const {options} = args;
    return useMutation({
      mutationKey: [MutationKey],
      mutationFn: async values => {
        const axios = await instApiAxios();

        return axios
          .delete<IOperator>(`/user/operator/${values.operatorId}`)
          .then(response => response.data);
      },
      ...options,
    });
  };

export default MutationKey;
