import {useQuery} from '@tanstack/react-query';
import {has, map, pick} from 'ramda';
import {IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/user/operator/findpwd';

interface UseQueryFindPasswordValues {
  email?: string;
  username?: string;
  businessNumber?: string;
  cellphone?: string;
}

/**
 * Operator password 찾기 api
 * email 값이 있으면 email 로 검색,
 * 없으면 businessNumber, cellphone 으로 검색
 *
 * @param args
 */
export const useQueryFindPassword: IUseQuery5<
  string,
  Partial<UseQueryFindPasswordValues>
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey],
    queryFn: async () => {
      const axios = await instApiAxios();

      const byEmail = has('email', values);
      const params = byEmail
        ? pick(['email', 'username'], values)
        : map(
            (value: string) => value.replace(/-/g, ''),
            pick(['businessNumber', 'cellphone', 'username'], values ?? {})
          );

      return axios
        .get(
          byEmail
            ? '/user/operator/findpwd/email'
            : '/user/operator/findpwd/cellphone',
          {
            params,
          }
        )
        .then(response => response.data);
    },
    enabled: false,
    ...options,
  });
};

export default QueryKey;
