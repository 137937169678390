import {path, isEmpty, isNilEmpty} from '@kakaoent/ops-design';
import type {ApiError} from '../types';

export * from './auth';
export * from './gateway';
export * from './partner';
export * from './product';
export * from './settlement';
export * from './user';
import {getEnv} from '../utils/getEnv';
import {getToken} from './_axios';

export const getApiErrorText = function (
  error?: ApiError | null,
  alt?: string
): string {
  let errorMessage = path<string>(
    ['response', 'data', 'customDetail'],
    error ?? {}
  ) as string | undefined;
  if (isEmpty(errorMessage ?? '')) {
    errorMessage = alt ?? '';
  }

  return errorMessage as string;
};

export const createApiError = function (message: string) {
  return {
    response: {
      data: {
        customDetail: message,
      },
    },
  };
};

export function getPartnerSsoUrl(
  values: {isSp?: boolean; targetUrl?: string} = {}
) {
  const tokenInfo = getToken(localStorage);
  const ssoDomain = !values.isSp
    ? getEnv('URL_ASIS_PARTNER')
    : getEnv('URL_ASIS_PARTNER_SP');

  function getTargetUrlQuery() {
    if (isNilEmpty(values.targetUrl)) {
      return '&targetUrl=/partner/main';
    }
    return `&targetUrl=${values.targetUrl}`;
  }
  return (
    `${ssoDomain}/partner/auth/sso` +
    `?accessToken=${tokenInfo.accessToken}` +
    `&refreshToken=${tokenInfo.refreshToken}` +
    getTargetUrlQuery()
  );
}
