import {useMutation} from '@tanstack/react-query';
import type {ISeriesForm, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';
import {mapSeriesFormToApiValues} from './_mapSeriesFormToApiValues';

/**
 * 작품 생성
 */
export const useCreateSeries: IUseMutation5<
  {seriesId: number},
  Partial<ISeriesForm>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async function (values) {
      const axios = await instApiAxios();

      const filteredValues = mapSeriesFormToApiValues(values);

      return axios
        .post('/product/series', filteredValues)
        .then(response => response.data);
    },
    ...options,
  });
};
