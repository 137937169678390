import {useQuery} from '@tanstack/react-query';
import type {IEditorAppVersionResponse, IUseQuery5} from '../../types';
import {instApiAxios} from '../_axios';

const QueryKey = 'GET:/partner/editorapp/update/check';

/**
 * 파트너 안드로이드 앱 버전 가져오기
 * @param args
 * @returns
 */
export const useQueryEditorAppVersion: IUseQuery5<
  IEditorAppVersionResponse,
  {appVersion?: string}
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get<IEditorAppVersionResponse>('/partner/editorapp/update/check', {
          params: values,
        })
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
