import {useInfiniteQuery} from '@tanstack/react-query';
import {propOr} from 'ramda';
import type {
  IApiResponse,
  IAuthor,
  IPagination,
  IQueryMetadata,
  IUseInfiniteQuery5,
} from '../../../types';
import {instApiAxios} from '../../_axios';
import {getNextPageParam} from '../../_queryClient5';

const QueryKey = 'GET:/product/author';

export interface QueryAuthorListValues extends IPagination {
  searchType: 'UID' | 'NAME';
  searchKeyword?: string;
}

/**
 * 작가 리스트 조회
 */
export const useInfQueryAuthorList: IUseInfiniteQuery5<
  IApiResponse<
    {
      authorList: IAuthor[];
    },
    IQueryMetadata
  >,
  QueryAuthorListValues
> = function ({values, options} = {}) {
  return useInfiniteQuery({
    queryKey: [QueryKey, values],
    queryFn: async function ({pageParam}) {
      const axios = await instApiAxios();
      return axios
        .get('/product/author', {
          params: {
            ...values,
            paginationOffset: propOr(0, 'paginationOffset', pageParam),
            paginationLimit: propOr(50, 'paginationLimit', pageParam),
          },
        })
        .then(
          response =>
            response.data as IApiResponse<{authorList: Array<IAuthor>}>
        );
    },
    initialPageParam: {paginationOffset: 0, paginationLimit: 50},
    getNextPageParam,
    ...options,
  });
};

export default QueryKey;
