import {useMutation} from '@tanstack/react-query';
import {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * Operator 휴면 계정 활성화
 * @param args
 * @returns
 */
export const useUpdateDormantUndo: IUseMutation5<
  boolean,
  {operatorId: number}
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async values => {
      const axios = await instApiAxios();
      return axios
        .put<boolean>(`/user/operator/${values.operatorId}/dormant/undo`)
        .then(response => response.data);
    },
    ...options,
  });
};
