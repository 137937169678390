import {useQuery} from '@tanstack/react-query';
import {equals, reject} from 'ramda';
import type {
  ISeriesAgeGradeDTO,
  IUseQuery5,
  LanguageCode,
} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/property/series/age-grade';

/**
 * 작품 연령등급 DTO정보 조회
 * @param values
 * @param options
 * @returns
 */
export const useQuerySeriesAgegradeDTO: IUseQuery5<
  ISeriesAgeGradeDTO[],
  {language?: LanguageCode; useAdult?: boolean}
> = function ({values = {}, options} = {}) {
  return useQuery({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const {language, useAdult} = values;
      const axios = await instApiAxios();
      return axios
        .get('/product/property/series/age-grade', {params: {language}})
        .then(response => {
          const {data = []} = response;
          return useAdult
            ? data
            : reject(
                (agegradeDTO: ISeriesAgeGradeDTO) =>
                  equals(String(agegradeDTO.ageGrade), '19'),
                data
              );
        });
    },
    ...options,
  });
};

export default QueryKey;
