import {prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ISeriesNoticeDTO, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';
import {instQueryClient} from '../../_queryClient5';
import SeriesNoticeListQueryKey from '../series/useQuerySeriesNoticeList';
import SeriesNoticeQueryKey from './useQuerySeriesNotice';

const MutationKey = 'DELETE:/product/notice/{noticeUid}';
interface IQuerySeriesNoticeValues {
  noticeUid: number;
}

/**
 * 작품 공지사항 삭제
 *
 *
 */
export const useDeleteSeriesNotice: IUseMutation5<
  ISeriesNoticeDTO,
  IQuerySeriesNoticeValues
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const noticeUid = prop('noticeUid', values);

      return await axios
        .delete(`/product/notice/${noticeUid}`, {
          params: values,
        })
        .then(response => response.data);
    },
    onSuccess: () => {
      const queryClient = instQueryClient();
      queryClient.invalidateQueries({queryKey: [SeriesNoticeListQueryKey]});
      queryClient.invalidateQueries({queryKey: [SeriesNoticeQueryKey]});
    },
    ...options,
  });
};

export default MutationKey;
