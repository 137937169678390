import {pick, prop} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ITicketTypeValue, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

const MutationKey = 'PUT:/product/series/{seriesId}/price/ticket-type';

export interface UpdateSeriesSaleType {
  seriesId: number;
  ticketType: ITicketTypeValue[];
  seriesDiscountOption?: {
    useDiscountOption?: boolean;
    discountRate?: number;
  };
}

/**
 * 작품 판매유형 정보 변경
 * @param options
 * @returns
 */
export const useUpdateSeriesSaleType: IUseMutation5<{}, UpdateSeriesSaleType> =
  function (args = {}) {
    const {options} = args;
    return useMutation({
      mutationKey: [MutationKey],
      mutationFn: async function (values) {
        const seriesId = prop('seriesId', values);

        const axios = await instApiAxios();
        const result = await axios
          .put(
            `/product/series/${seriesId}/sale-type`,
            pick(['ticketType', 'seriesDiscountOption'], values),
            {
              headers: {'Content-Type': 'application/json;'},
              timeout: 0,
            }
          )
          .then(response => response.data);

        return result;
      },
      ...options,
    });
  };

export default MutationKey;
