import {
  equals,
  includes,
  map,
  omit,
  pick,
  pipe,
  prop,
} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import moment from 'moment';
import type {
  ISetSeries,
  ISetSeriesMappedSingleInfo,
  ISetSeriesStateInfo,
  IUseMutation5,
} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'PUT:product/series/{seriesId}/set/{setSeriesUid}';

export interface UpdateSetSeriesValues extends Partial<ISetSeries> {
  seriesId?: number;
  setSeriesUid?: number;
}

/**
 * 세트상품 수정
 */
export const useUpdateSetSeries: IUseMutation5<
  ISetSeries,
  Partial<UpdateSetSeriesValues>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [QueryKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const seriesId = prop('seriesId', values);
      const setSeriesUid = prop('setSeriesUid', values);

      return await axios
        .put<ISetSeries>(
          `/product/series/${seriesId}/set/${setSeriesUid}`,
          filtersetSeriesValues(values)
        )
        .then(response => response.data);
    },
    onSuccess: function () {},
    ...options,
  });
};

function filtersetSeriesValues(setSeries: UpdateSetSeriesValues) {
  const isUpdateBlock = includes(
    setSeries.setSeriesStateInfo?.stateAndSaleMethod,
    [
      'VALIDATE_SUMBIT', // 심사신청대기
      'SUBMIT', // 심사신청
      'FIRST_RESERVE_ON_SALE', // 판매예약
      'RESTART_RESERVE_ON_SALE', // 판매재시작예약
      'VALIDATE_RESTART_RESERVE_ON_SALE', // 판매예약대기
      'ON_SALE', // 판매중
      'STOP_SALE', // 판매중지
    ]
  );
  const isSalePeriod =
    equals(setSeries.setSeriesStateInfo?.stateAndSaleMethod, 'ON_SALE') &&
    moment(setSeries.setSeriesStateInfo?.reservedSaleStartDt)
      .set({second: 0, millisecond: 0})
      .isBefore(moment().set({second: 0, millisecond: 0}).toDate());

  return pipe(
    (values: UpdateSetSeriesValues) => ({
      ...values,
      ...{
        ticketTypeInfo: omit(
          equals(values.ticketTypeInfo?.ticketType, 'TT')
            ? ['rentalPeriod']
            : [],
          {
            ...values.ticketTypeInfo,
            ticketType: values.ticketTypeInfo?.ticketType,
          }
        ),
        setSeriesStateInfo: {
          ...values.setSeriesStateInfo,
          reservedSaleStartDt: moment(
            values.setSeriesStateInfo?.reservedSaleStartDt
          ).toISOString(),
          reservedSaleEndDt: moment(
            values.setSeriesStateInfo?.reservedSaleEndDt
          ).toISOString(),
        },
      },
    }),
    omit(['uid', 'seriesId', 'setSeriesUid', 'saleEndDtIndefinite']),
    (values: UpdateSetSeriesValues) =>
      isUpdateBlock
        ? omit(
            ['mappedSingleInfoList', 'ticketTypeInfo', 'productSaleInfo'],
            values
          )
        : {
            ...values,
            mappedSingleInfoList: map(
              (single: ISetSeriesMappedSingleInfo) => ({
                singleId: single.singleId,
              }),
              values.mappedSingleInfoList ?? []
            ),
          },
    (values: UpdateSetSeriesValues) => ({
      ...values,
      ...{
        setSeriesStateInfo: isSalePeriod
          ? omit(
              [
                'reservedSaleStartDt',
                'stateAndSaleMethod',
                'stateAndSaleMethodName',
              ],
              (values.setSeriesStateInfo as ISetSeriesStateInfo) ?? {}
            )
          : pick(
              ['reservedSaleStartDt', 'reservedSaleEndDt', 'hideSalePeriod'],
              (values.setSeriesStateInfo as ISetSeriesStateInfo) ?? {}
            ),
      },
    })
  )(setSeries);
}

export default QueryKey;
