import {useQuery} from '@tanstack/react-query';
import type {ISeriesPrice, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/series/{seriesId}/sale';

/**
 * 작품 판매설정 정보 조회
 * @param values
 * @param options
 * @returns QueryObserverResult
 */
export const useQuerySeriesPrice: IUseQuery5<ISeriesPrice, {seriesId: number}> =
  function ({values, options} = {}) {
    return useQuery({
      queryKey: [QueryKey, values],
      queryFn: async function () {
        const axios = await instApiAxios();
        return axios
          .get(`product/series/${values?.seriesId}/price`)
          .then(response => response.data);
      },
      ...options,
    });
  };

export default QueryKey;
