import {omit} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import type {ISeriesForm, IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';
import {instQueryClient} from '../../_queryClient5';
import {mapSeriesFormToApiValues} from './_mapSeriesFormToApiValues';
import SeriesQueryKey from './useQuerySeries';
import SeriesListQueryKey from './useQuerySeriesList';

/**
 * 작품 변경
 */
export const useUpdateSeries: IUseMutation5<
  boolean,
  Partial<ISeriesForm>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async function (values) {
      const axios = await instApiAxios();

      const filteredValues = mapSeriesFormToApiValues(values);

      return axios
        .put(
          `/product/series/${values?.seriesId}`,
          omit(['seriesId'], filteredValues)
        )
        .then(response => response.data);
    },
    onSuccess: () => {
      const queryClient = instQueryClient();
      queryClient.invalidateQueries({queryKey: [SeriesQueryKey]});
      queryClient.invalidateQueries({queryKey: [SeriesListQueryKey]});
    },
    ...options,
  });
};
