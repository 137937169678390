import {useQuery} from '@tanstack/react-query';
import {IUseQuery5} from '../../types';
import {instApiAxios} from '../_axios';

const QueryKey = 'GET:/partner/settlement/checkSettlementDone';

// TODO : 월정산 조회 API / 사용하는 곳 없음
interface QueryNewMontylyValues {
  searchFromMonth: string;
  searchFromYear: string;
}

export const useQueryNewMontyly: IUseQuery5<any, QueryNewMontylyValues> =
  function ({values, options} = {}) {
    return useQuery({
      queryKey: [QueryKey, values],
      queryFn: async () => {
        const axios = await instApiAxios();

        return '';
      },
      ...options,
    });
  };

export default QueryKey;
