import {useMutation} from '@tanstack/react-query';
import {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * 계좌인증 Mutation
 * @param args
 * @returns
 */
export const useCreateBankValidation: IUseMutation5<
  boolean,
  Partial<{
    bankAccount: string;
    bankCode: string;
    bankOwner: string;
  }>
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async values => {
      const axios = await instApiAxios();
      return axios
        .post<boolean>('/user/publisher/validate/bank', values, {
          headers: {'Content-Type': 'application/json'},
        })
        .then(response => response.data);
    },
    ...options,
  });
};
