import {useQuery} from '@tanstack/react-query';
import type {ISetSeriesFilterTicketType, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/property/set/ticket-type';

/**
 * 세트상품 목록 판매유형 필터 조회
 * @param options
 * @returns
 */
export const useQuerySetSeriesTicketTypeFilter: IUseQuery5<
  ISetSeriesFilterTicketType[]
> = function ({options} = {}) {
  return useQuery({
    queryKey: [QueryKey],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get('/product/property/set/ticket-type')
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
