import {isNilEmpty} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {ApiError, IKakaowebtoonSeries, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:product/external/kakaowebtoon/series/${seriesId}/info';

export interface QueryKakaowebtoonSeriesValues {
  seriesId?: number;
}

/**
 * 카카오웹툰 작품 정보 조회
 * @param {Object} params
 * @param {QueryKakaowebtoonSeriesValues} params.values - 카카오웹툰 작품 정보 조회 값
 * @param {UseQueryOptions} params.options - 카카오웹툰 작품 정보 조회 쿼리 옵션
 * @returns QueryObserverResult
 */
export const useQueryKakaowebtoonSeries: IUseQuery5<
  IKakaowebtoonSeries,
  QueryKakaowebtoonSeriesValues
> = function ({values, options} = {}) {
  return useQuery<IKakaowebtoonSeries, ApiError>({
    queryKey: [QueryKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get(`product/external/kakaowebtoon/series/${values?.seriesId}/info`)
        .then(response => response.data);
    },
    enabled: !isNilEmpty(values?.seriesId),
    ...options,
  });
};

export default QueryKey;
