import {isNilEmpty} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import {pick} from 'ramda';
import RSA_PUBLIC_JSON from '../../../.secret/rsa_public_key.json';
import {IOperator, IUseMutation5} from '../../../types';
import {encryptRSA} from '../../../utils/crypto';
import {instServerAxios} from '../../_axios';

const MutationKey = 'PUT:/user/operator';

export interface UpdateOperatorValues {
  operator?: Partial<
    Pick<
      IOperator,
      'realName' | 'cellphone' | 'email' | 'id' | 'currPwd' | 'newPwd'
    >
  >;
}

/**
 * Operator 회원정보 수정
 *
 * @param args
 * @returns
 */
export const useUpdateOperator: IUseMutation5<IOperator, UpdateOperatorValues> =
  function (args = {}) {
    const {options} = args;

    return useMutation({
      mutationKey: [MutationKey],
      mutationFn: async values => {
        const axios = await instServerAxios();
        const operatorId = values?.operator?.id;
        const apiValues = pick(
          ['currPwd', 'newPwd', 'realName', 'cellphone', 'email'],
          values.operator ?? {}
        );

        return await axios
          .put(`api/user/operator/${operatorId}`, {
            ...apiValues,
            currPwd: isNilEmpty(apiValues?.currPwd)
              ? undefined
              : encryptRSA(RSA_PUBLIC_JSON.pem, apiValues?.currPwd),
            newPwd: isNilEmpty(apiValues?.newPwd)
              ? undefined
              : encryptRSA(RSA_PUBLIC_JSON.pem, apiValues?.newPwd),
          })
          .then(response => response.data);
      },
      ...options,
    });
  };

export default MutationKey;
