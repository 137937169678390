import {useMutation} from '@tanstack/react-query';
import type {IUseMutation5} from '../../../types';
import {mapFormData} from '../../../utils/network';
import {instApiAxios} from '../../_axios';

const MutationKey = 'POST:/product/series/image/portrait';

export interface UploadPortraitImageValues {
  uploadImageFile?: File;
}

/**
 * 작품 대표이미지 업로드(케이지)
 */
export const useUploadPortraitImage: IUseMutation5<
  {uploadId: string; originalFilename?: string},
  UploadPortraitImageValues
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationKey: [MutationKey],
    mutationFn: async function (values) {
      const axios = await instApiAxios();
      const formData: FormData = mapFormData(values);
      return axios
        .post('/product/series/image/portrait', formData, {
          headers: {'Content-Type': 'multipart/form-data;'},
          timeout: 0,
        })
        .then(response => response.data);
    },
    ...options,
  });
};

export default MutationKey;
