import {isNil} from '@kakaoent/ops-design';
import {useQuery} from '@tanstack/react-query';
import type {ApiError, ISeriesTicket, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueyrKey = 'GET:/product/series/{seriesId}/ticket';

export interface QuerySeriesTicketValues {
  seriesId: number;
}

/**
 * 작품 이용권정보 조회
 * @param {Object} params
 * @param {QuerySeriesTicketValues} params.values
 * @param {UseQueryOptions<ISeriesTicket, ApiError, ISeriesTicket, QueryKey>} params.options - 작품 이용권정보 조회 쿼리 옵션
 * @returns {QueryObserverResult}
 */
export const useQuerySeriesTicket: IUseQuery5<
  ISeriesTicket,
  QuerySeriesTicketValues
> = function ({values, options} = {}) {
  return useQuery<ISeriesTicket, ApiError>({
    queryKey: [QueyrKey, values],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get(`product/series/${values?.seriesId}/ticket`)
        .then(response => response.data);
    },
    enabled: !isNil(values?.seriesId),
    ...options,
  });
};

export default QueyrKey;
