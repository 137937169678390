import {useMutation} from '@tanstack/react-query';
import {IUseMutation5} from '../../../types';
import {instApiAxios} from '../../_axios';

/**
 * email otp 번호 확인 api
 * @param args
 */
// TODO: query로 바꾸기
export const useConfirmEmailOtp: IUseMutation5<
  boolean,
  {email: string; otp: string}
> = function (args = {}) {
  const {options} = args;
  return useMutation({
    mutationFn: async (values: {email: string; otp: string}) => {
      const axios = await instApiAxios();
      return axios
        .get<boolean>('/user/operator/validate/email/otp', {
          params: values,
        })
        .then(response => response.data);
    },
    ...options,
  });
};
