import {useQuery} from '@tanstack/react-query';
import type {ISeriesThemeKeywordGroup, IUseQuery5} from '../../../types';
import {instApiAxios} from '../../_axios';

const QueryKey = 'GET:/product/property/series/keyword/theme';

/**
 * 작품 테마키워드 정보 조회
 * @param values
 * @param options
 * @returns
 */
export const useQuerySeriesThemeKeyword: IUseQuery5<
  ISeriesThemeKeywordGroup[],
  {categoryUid?: number; subCategoryUid?: number}
> = function ({values, options} = {}) {
  return useQuery({
    queryKey: [QueryKey],
    queryFn: async function () {
      const axios = await instApiAxios();
      return axios
        .get('/product/property/series/keyword/theme', {params: values})
        .then(response => response.data);
    },
    ...options,
  });
};

export default QueryKey;
