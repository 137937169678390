import {waitPromise} from './waitPromise';

export async function waitSentry(retryCount: number = 5) {
  try {
    const sentryInstance = await import('@sentry/nextjs');

    if (!sentryInstance.isInitialized()) {
      throw new Error('Sentry environments are not set');
    }

    return sentryInstance;
  } catch (error) {
    if (retryCount <= 0) {
      throw error;
    }
    await waitPromise(500);
    return await waitSentry(retryCount - 1);
  }
}
