import {pick, propOr} from '@kakaoent/ops-design';
import {useInfiniteQuery} from '@tanstack/react-query';
import type {
  IApiResponse,
  IPagination,
  IQueryMetadata,
  ISingleState,
  ISlide,
  ISlideType,
  IUseInfiniteQuery5,
} from '../../../types';
import {instApiAxios} from '../../_axios';
import {getNextPageParam} from '../../_queryClient5';

const QueryKey = 'GET:/product/contents/slide';

export interface IUseInfQuerySlideListValues
  extends Pick<IPagination, 'paginationSort'> {
  isPackaged: boolean;
  slideTypes?: ISlideType[];
  slideStates?: ISingleState[];
}

/**
 * 슬라이드 목록을 조회합니다.
 * @param values
 * @param options
 * @returns
 */
export const useInfQuerySlideList: IUseInfiniteQuery5<
  IApiResponse<{slides: ISlide[]}, IQueryMetadata>,
  IUseInfQuerySlideListValues
> = function ({values, options} = {}) {
  return useInfiniteQuery({
    queryKey: [QueryKey, values],
    queryFn: async function ({pageParam}) {
      const axios = await instApiAxios();
      return axios
        .get('/product/contents/slide', {
          params: {
            ...values,
            ...pick(
              ['isPackaged', 'slideTypes', 'slideStates', 'paginationSort'],
              values ?? ({} as IUseInfQuerySlideListValues)
            ),
            paginationOffset: propOr(0, 'paginationOffset', pageParam),
            paginationLimit: propOr(50, 'paginationLimit', pageParam),
          },
        })
        .then(response => response.data);
    },
    initialPageParam: {paginationOffset: 0, paginationLimit: 50},
    getNextPageParam,
    ...options,
  });
};

export default QueryKey;
