import {isNilEmpty, toLower} from '@kakaoent/ops-design';
import {useMutation} from '@tanstack/react-query';
import RSA_PUBLIC_JSON from '../../.secret/rsa_public_key.json';
import type {IUseMutation5} from '../../types';
import {encryptRSA} from '../../utils/crypto';
import {isAppUserAgent} from '../../utils/network';
import {
  instLocalStorage,
  instServerAxios,
  removeToken,
  removeUsername,
  setAndroidApp,
  setSpLogined,
  setToken,
  setUsername,
} from '../_axios';

interface UseCreateTokenValues {
  username?: string;
  password?: string;
  reCaptchaToken?: string;
  isSave?: boolean;
  isSp?: boolean;
}

interface TokenQueryResult {
  tokenType: string;
  refreshToken: string;
  accessToken: string;
  expires: string;
}

export const useCreateToken: IUseMutation5<
  TokenQueryResult,
  UseCreateTokenValues
> = function (args = {}) {
  const storage = instLocalStorage();
  const {options} = args;

  return useMutation({
    mutationFn: async function (values) {
      // ID 저장
      if (values.isSave && !isNilEmpty(values?.username)) {
        setUsername(storage, values?.username, values?.isSp);
      } else {
        removeUsername(storage, values?.isSp);
      }

      // 비밀번호 암호화
      const encryptedPassword = encryptRSA(
        RSA_PUBLIC_JSON.pem,
        values?.password ?? ''
      );

      // 토큰 생성 api 호출
      const isAndroidApp = isAppUserAgent();
      const axios = await instServerAxios();
      const response = await axios
        .post<TokenQueryResult>('api/auth/token', {
          username: toLower(values?.username ?? ''),
          password: encryptedPassword,
          reCaptchaToken: values?.reCaptchaToken,
          grantType: 'password',
          loginAgent: isAndroidApp ? 'APP' : !values.isSp ? 'CP' : 'SP',
        })
        .catch(error => {
          removeToken(storage);
          throw error;
        });

      setToken(storage, {
        tokenType: response.data?.tokenType,
        refreshToken: response.data?.refreshToken,
        accessToken: response.data?.accessToken,
        expires: response.data?.expires,
      });
      setSpLogined(storage, values.isSp);
      setAndroidApp(storage, isAndroidApp);
      storage.removeItem('seriesSearchFilter');

      return response.data;
    },
    ...options,
  });
};
